import { Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Contact = (props) => {
    const { pageSize } = props;

    const ContactContainer = styled(Box)`
    margin: 10vh 0 0 0;
    display: flex;
    width: 90vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    `

    const MainHeading = styled(Typography)`
    width: 60vw;
    font-family: Montserrat, sans-serif;
    font-size: ${props => props.pageSize === 'mobile' ? '10vw' : '5vw'};
    font-weight: 200;
    color: rgb(245,158,11);
    text-align: center;
    `

    const SubHeading = styled(Typography)`
    margin: 2vh 0 0 0;
    width: 60vw;
    font-family: Montserrat, sans-serif;
    font-size: ${props => props.pageSize === 'mobile' ? '6vw' : '3vw'};
    font-weight: 200;
    color: rgb(245,158,11);
    text-align: center;
    `

    const ContactText = styled(Typography)`
    margin: 4vh 0 0 0;
    width: 60vw;
    font-family: Montserrat, sans-serif;
    font-size: ${props => props.pageSize === 'mobile' ? '5vw' : '2vw'};;
    font-weight: 200;
    color: rgb(255,255,255);
    text-align: center;
    `

    return (
        <ContactContainer>
            <MainHeading pageSize={pageSize}>CONTACT</MainHeading>
            <SubHeading pageSize={pageSize}>Say hello... <span><i className="fa-thin fa-hand-wave"></i></span></SubHeading>
            <ContactText pageSize={pageSize}>
                <p>Feel free to get in touch!</p>
                <p><br/><i className="fa-thin fa-circle-envelope fa-2x"></i> <a href="mailto:davidpaulbuckley@gmail.com" rel='noreferrer' target='_blank'>davidpaulbuckley@gmail.com</a></p>
                <p><br/><i class="fa-brands fa-linkedin-in fa-2x"></i> <a href="https://www.linkedin.com/in/david-buckley-tech/" rel='noreferrer' target='_blank'>David Buckley</a></p>
                <p><br/><i class="fa-brands fa-github fa-2x"></i> <a href="https://github.com/davebdev" rel='noreferrer' target='_blank'>@davebdev</a></p>
                <p><br/><i class="fa-brands fa-twitter fa-2x"></i> <a href="https://twitter.com/davidpbuckley" rel='noreferrer' target='_blank'>@davidpbuckley</a></p>
            </ContactText>
        </ContactContainer>
    )

}

export default Contact;