import { Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Home = (props) => {
    const { pageSize } = props;

    const HomeContainer = styled(Box)`
    margin: 10vh 0 0 0;
    display: flex;
    width: 90vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    `

    const MainHeading = styled(Typography)`
    width: 60vw;
    font-family: Montserrat, sans-serif;
    font-size: ${props => props.pageSize === 'mobile' ? '10vw' : '5vw'};
    font-weight: 200;
    color: rgb(245,158,11);
    text-align: ${props => props.pageSize === 'mobile' ? 'left' : 'center'}
    `

    const LeftSpan = styled(Typography)`
    width: 60vw;
    font-family: Montserrat, sans-serif;
    font-size: ${props => props.pageSize === 'mobile' ? '10vw' : '5vw'};
    font-weight: 200;
    color: rgb(245,158,11);
    text-align: left;
    `

    const RightSpan = styled(Typography)`
    width: 60vw;
    font-family: Montserrat, sans-serif;
    font-size: ${props => props.pageSize === 'mobile' ? '10vw' : '5vw'};
    font-weight: 200;
    color: rgb(245,158,11);
    text-align: right;
    `

    const SubHeading = styled(Typography)`
    margin: 2vh 0 0 0;
    width: 60vw;
    font-family: Montserrat, sans-serif;
    font-size: ${props => props.pageSize === 'mobile' ? '4vw' : '3vw'};
    font-weight: 200;
    color: rgb(245,158,11);
    text-align: center;
    `

    return (
        <HomeContainer>
            <LeftSpan pageSize={pageSize}>&lt;</LeftSpan>
            <MainHeading pageSize={pageSize}>DAVE BUCKLEY</MainHeading>
            <RightSpan pageSize={pageSize}>/&gt;</RightSpan>
            <SubHeading pageSize={pageSize}>SOFTWARE DEVELOPER</SubHeading>
        </HomeContainer>
    )

}

export default Home;