import { Typography, Box } from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import projectData from './projectData';

const Projects = (props) => {
    const [projects, setProjects] = useState(projectData);
    const { pageSize } = props;

    const Skills = [
        {
            name: 'All',
            id: 'all',
            description: '',
            icon: 'fa-thin fa-list-check',
            color: 'rgb(255,255,255,1)'
        },
        {
            name: 'Git & Github',
            id: 'git',
            description: 'Description',
            icon: 'fa-brands fa-git-alt',
            color: 'rgb(232,77,50,1)'
        },
        {
            name: 'HTML',
            id: 'html',
            description: 'I\'ve worked with HTML in a professional capacity for about 4 years now, first with my job as a Technical Analyst / Developer at City, University of London, then (in parts) at my current job at UNSW, as well as intensively as an integral part of the Sotware Engineering intensive at General Assembly.',
            icon: 'fa-brands fa-html5',
            color: 'rgb(222,75,37,1)'
        },
        {
            name: 'CSS',
            id: 'css',
            description: 'In a simliar fashion to HTML, I\'ve been working with CSS for about four years, and while I understand CSS fundamentals (including the newer Flexbox and Grid), getting clean, responsive, mobile-friendly CSS is still something I am working on.',
            icon: 'fa-brands fa-css3-alt',
            color: 'rgba(38,98,233,1)'
        },
        {
            name: 'JavaScript',
            id: 'javascript',
            description: 'Over the course of the Software Engineering intensive at General Assembly I have grown to love JavaScript and the many endless possibilities and permeatations it presents.',
            icon: 'fa-brands fa-square-js',
            color: 'rgba(239,216,29,1)'
        },
        {
            name: 'Node.js',
            id: 'node',
            description: 'We have used Node.js in three of the four projects we completed ',
            icon: 'fa-brands fa-node-js',
            color: 'rgba(3,110,2,1)'
        },
        {
            name: 'PostgreSQL',
            id: 'postgres',
            description: 'Description',
            icon: 'fa-thin fa-database',
            color: 'rgba(51,103,144,1)'
        },
        {
            name: 'Python',
            id: 'python',
            description: 'Description',
            icon: 'fa-brands fa-python',
            color: 'rgba(247,202,63,1)'
        },
        {
            name: 'Express.js',
            id: 'express',
            description: 'Description',
            icon: 'fa-kit fa-expressjs',
            color: 'rgba(0,0,0,1)'
        },
        {
            name: 'React',
            id: 'react',
            description: 'We were introduced to React in the final unit of the intensive at GA and my final project was built using it. I\'ve become very proficient at basic React function and class components, and very much want to learn more.',
            icon: 'fa-brands fa-react',
            color: 'rgba(97,218,251,1)'
        },
    ]

    const ProjectsContainer = styled(Box)`
    margin: 10vh 0;
    display: flex;
    width: 90vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    `

    const MainHeading = styled(Typography)`
    width: 60vw;
    font-family: Montserrat, sans-serif;
    font-size: ${props => props.pageSize === 'mobile' ? '10vw' : '5vw'};
    font-weight: 200;
    color: rgb(245,158,11);
    text-align: center;
    `

    const SubHeading = styled(Typography)`
    margin: 2vh 0 0 0;
    width: 60vw;
    font-family: Montserrat, sans-serif;
    font-size: 7vh;
    font-weight: 200;
    color: rgb(245,158,11);
    text-align: center;
    `

    const ProjectsListBox = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    margin: 4vh 0 0 0;
    width: 60vw;
    font-family: Montserrat, sans-serif;
    font-size: ${props => props.pageSize === 'mobile' ? '5vw' : '1.2rem'};;
    font-weight: 200;
    color: rgb(255,255,255);
    text-align: center;
    `

    const SkillsListBox = styled(Box)`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    column-gap: 10px;
    row-gap: 10px;
    justify-content: ${props => props.pageSize === 'mobile' ? 'center' : 'space-between'};
    margin: 4vh 0 0 0;
    width: 60vw;
    font-family: Montserrat, sans-serif;
    font-weight: 200;
    color: rgb(255,255,255);
    text-align: left;
    `

    const SkillsListItem = styled(Typography)`
    font-size: 1.5em;
    font-weight: 200;
    background-color: rgba(255,255,255,0.3);
    border-radius: 8px;
    padding: 5px 8px;
    :hover {
        background-color: rgba(255,255,255,0.5);
        cursor: pointer;
    }
    `

    const filterProjects = (e) => {
        const results = []
        for (let i = 0; i < projectData.length; i++) {
            const skillsArr = projectData[i].skills;
            if (skillsArr.includes(e.target.id)) {
                results.push(projectData[i])
            }
        }
        setProjects(results);
    }

    const SkillsList = (props) => {

        return (
            <SkillsListItem id={props.skill.id} onClick={(e) => filterProjects(e)}>
                <span style={{color: props.skill.color}} className=''><i className={props.skill.icon}></i></span> {props.skill.name}
            </SkillsListItem>
        )
    }

    const ProjectsList = (props) => {
        return (
            <span className="projectLink" key={props.project.id}>
                <a href={props.project.appUrl} rel='noreferrer' target='_blank'><i className={props.project.icon}></i> {props.project.name}</a>
                {props.project.description}
                <em>Github link: <a href={props.project.githubUrl} rel='noreferrer' target='_blank'>{props.project.githubUrl}</a></em>
            </span>
        )
    }


    return (
        <ProjectsContainer>
            <MainHeading pageSize={pageSize}>PROJECTS</MainHeading>
            <SubHeading pageSize={pageSize}><span><i className="fa-thin fa-list-check"></i></span></SubHeading>
            <SkillsListBox pageSize={pageSize}>
                {Skills.map(skill => <SkillsList skill={skill} />)}
            </SkillsListBox>
            <ProjectsListBox pageSize={pageSize}>
                {projects.map(project => <ProjectsList project={project} />)}
            </ProjectsListBox>
        </ProjectsContainer>
    )

}

export default Projects;