import { Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const About = (props) => {
    const { pageSize } = props;

    const AboutContainer = styled(Box)`
    margin: 10vh 0 0 0;
    display: flex;
    width: 90vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    `

    const MainHeading = styled(Typography)`
    width: 60vw;
    font-family: Montserrat, sans-serif;
    font-size: ${props => props.pageSize === 'mobile' ? '10vw' : '5vw'};
    font-weight: 200;
    color: rgb(245,158,11);
    text-align: center;
    `

    const SubHeading = styled(Typography)`
    margin: 2vh 0 0 0;
    width: 60vw;
    font-family: Montserrat, sans-serif;
    font-size: ${props => props.pageSize === 'mobile' ? '4vw' : '3vw'};
    font-weight: 200;
    color: rgb(245,158,11);
    text-align: center;
    `

    const AboutText = styled(Typography)`
    margin: 4vh 0 0 0;
    width: 60vw;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 200;
    color: rgb(255,255,255);
    text-align: center;
    `

    return (
        <AboutContainer>
            <MainHeading pageSize={pageSize}>ABOUT</MainHeading>
            <SubHeading pageSize={pageSize}>Hi there <span><i className="fa-thin fa-hand-wave"></i></span></SubHeading>
            <AboutText>
            I'm Dave, a recently trained software developer with a passion for accessible, user-friendly & innovative web applications.<br/>
            <br/>
            I value kindness, honesty and people who make room for being human. I get a wild amount of joy from learning new things, and love solving puzzles, putting pieces together, trying to figure out how things work & why they break. <br/>
            <br/>
            I spent 10 years working (on and off) in theatre, another 10 working in the higher education industry and finally found fulfilment in software engineering, first in a technical analyst/developer role at City, University of London, then again within the Software Engineering course at General Assembly, which I've just graduated from. This variety of experience creates a unique blend of skills that allows for service-oriented, and creative problem-solving in the developer world. <br/>
            <br/>
            I can't wait to get started.
            </AboutText>
        </AboutContainer>
    )

}

export default About;