// adapted from https://mui.com/material-ui/react-app-bar/#responsive-app-bar-with-drawer
import './App.css';
import { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AppBar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography, Button } from '@mui/material';
import { styled } from "@mui/material/styles";
import Home from './Home';
import About from './About';
import Projects from './Projects';
import Contact from './Contact';

const drawerWidth = 240;
const navItems = ['HOME', 'ABOUT', 'PROJECTS', 'CONTACT', <i className="fa-thin fa-file-pdf fa-2x"></i>];

const mainTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 730,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });

const App = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [page, setPage] = useState('HOME');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNav = (e) => {
    if (e.target.textContent === '') {
        const link = document.createElement('a');
        link.href = process.env.PUBLIC_URL + '/DBUCKLEY_RESUME.pdf'
        link.download = 'DBUCKLEY_RESUME.pdf'
        link.click();
        console.log('download resume');
    } else {
        setPage(e.target.textContent);
    }
  }

  const MenuBox = styled(Box)`
  color: rgb(245,158,11);
  background-color: rgba(9, 7, 51, 1);
  background-size: cover;
  height: 100vh;
  text-align: center;
  `;

  const CustomAppBar = styled(AppBar)`
  color: rgb(245,158,11);
  background-color: rgba(9, 7, 51, 1);
  `

  const NavButton = styled(Button)`
  color: rgb(245,158,11);
  font-family: Montserrat, sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  :hover {
    background-color: rgb(255,255,255,0.2)
  }
  margin: 0.5rem;
  `
  const NavListItemButton = styled(ListItemButton)`
  text-align: center;
  :hover {
    background-color: rgb(255,255,255,0.2)
  }
  `

  const MobileContainer = styled(Box)`
  text-align: center;
  `

  const DesktopContainer = styled(Box)`
  text-align: center;
  `

  const drawer = (
    <MenuBox onClick={handleDrawerToggle}>
      <Typography variant="h6" sx={{ my: 2 }}>
      <i className='fa-thin fa-unicorn fa-2x'>DOWNLOAD</i>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <NavListItemButton onClick={handleNav}>
              <ListItemText primary={item} />
            </NavListItemButton>
          </ListItem>
        ))}
      </List>
    </MenuBox>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={mainTheme}>
        <Box sx={{ display: 'flex' }} className="App">
        <CustomAppBar component="nav">
            <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
            >
                <i className='fa-light fa-bars'></i>
            </IconButton>
            <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
                <i className='fa-thin fa-unicorn fa-2x'></i>
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {navItems.map((item) => (
                <NavButton key={item} onClick={handleNav}>
                    {item}
                </NavButton>
                ))}
            </Box>
            </Toolbar>
        </CustomAppBar>
        <Box component="nav">
            <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            >
            {drawer}
            </Drawer>
        </Box>
        <Box component="main">
            {/* content goes here */}

            <MobileContainer sx={{ flexGrow: 1, display: { xs: 'block', sm: 'none' } }}>
                {page === 'HOME' && <Home pageSize='mobile'/>}
                {page === 'ABOUT' && <About pageSize='mobile'/>}
                {page === 'PROJECTS' && <Projects pageSize='mobile'/>}
                {page === 'CONTACT' && <Contact pageSize='mobile'/>}
            </MobileContainer>
            <DesktopContainer sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                {page === 'HOME' && <Home pageSize='desktop'/>}
                {page === 'ABOUT' && <About pageSize='desktop'/>}
                {page === 'PROJECTS' && <Projects pageSize='desktop'/>}
                {page === 'CONTACT' && <Contact pageSize='desktop'/>}
            </DesktopContainer>
        </Box>
        </Box>
    </ThemeProvider>
  );
}

export default App;
