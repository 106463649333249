const projectData =
     [{
        name: "Project 1 - Spellcheckers",
        description: "Spellcheckers is an online word game built primarily with vanilla javascript",
        githubUrl:"https://bit.ly/3WGV4ll",
        appUrl:"https://davebdev.github.io/project01/",
        icon:"fa-thin fa-game-board",
        skills: ["all","git","javascript","html","css"]
    },
    {
        name: "Project 2 - A-Lister",
        description: "A movie list maker to store and share all your 'to-watch' movies",
        githubUrl:"https://bit.ly/3CxGmDY",
        appUrl:"https://a-lister.onrender.com/",
        icon:"fa-thin fa-popcorn",
        skills: ["all","git","javascript","python","html","css"]
    },
    {
        name: "Project 3 - Tript",
        description: "Tript is a digital travel diary, allowing you to save details of all your favourite trips, activities & hotels. You can also search trips posted by other users allowing you to find new and exciting places to go.",
        githubUrl:"https://bit.ly/3CyC9Qf",
        appUrl:"https://ga-tript.herokuapp.com/",
        icon:"fa-thin fa-location-dot",
        skills: ["all","git","node","express","html","css","javascript","postgres"]
    },
    {
        name: "Project 4 - A Wedding Manager",
        description: "A web app used to send invites & information, gather RSVPs and manage guests for my wedding next year.",
        githubUrl:"https://bit.ly/3MTNQpH",
        appUrl:"https://wedding-mgr.onrender.com/",
        icon:"fa-thin fa-rings-wedding",
        skills: ["all","git","html","css","javascript","node","express","postgres","react"]
    }]

    export default projectData;